<template>
    <div v-loading="loading">
        <div class='wrapper'>
            <div class="clearfix">
                <div class='leftContent fl' v-if='diseaseList.length > 0'>
                    <div class='germplasmMenuList flex'>
                        <router-link tag="div" v-for='(item, index) in diseaseList' :key='index+ "g"'  :to="{ path: '/pestDataDetail', query:{id:item.id} }" >
                           <!-- <img :src="item.showImg" alt=""> -->
                           <el-image
                            style="width: 100%; height: 210px"
                            :src="item.showImg || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'"
                            fit="cover"></el-image>
                           <span class='ellipsis'>{{item.name}}</span>
                        </router-link>
                    </div>
                    <pagination v-if='page.total > page.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
                </div>
                <div v-else class='fl nodata'>
                    <no-data></no-data>
                </div>
            </div>
            <el-dialog
                title=""
                :visible.sync="tip"
                width="30%">
                <div class='msg'>请下载APP可查看更多信息</div>
                <div class='downLoad'><router-link  :to='{path: "/downLoad"}' tag='a'>下载APP</router-link></div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index.vue';
import hotDemand from '@/component/hotDemand/index.vue';
import Vue from 'vue';
import { Dialog, Image } from 'element-ui';
Vue.use(Dialog);
Vue.use(Image);
export default {
    components: {
        pagination,
        noData
    },
    data() {
        return {
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 10
            },
            diseaseList: [],
            diseaseTotalList: [],
            tip: false,
            loading: true
        };
    },
    mounted() {
        this.getHomeList();
    },
    methods: {
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            if (this.page.currentPage < 3) {
                this.onChangePageList(); // 改变页码，重新渲染页面
            } else {
                this.tip = true;
            }
        },
        // 全部的接口
        async getHomeList() {
            this.loading = true;
            const data = await this.$fetchData.fetchPost({}, this.$fetchApi.homeList.api, 'json');
            this.loading = false;
            if (data.code === '200') {
                if (data.result.length > 0) {
                    let diseasePestList = [];
                    data.result.forEach(item => {
                        if (item.diseasePestList.length > 0) {
                            diseasePestList = diseasePestList.concat(item.diseasePestList);
                        }
                    });
                    this.callBack(diseasePestList);
                }
            }
        },
        // 接口返回字段的处理
        callBack(diseasePestList) {
            this.diseaseTotalList = diseasePestList;
            this.diseaseList = diseasePestList;
            this.page.total = this.diseaseList.length;
            if (diseasePestList.length > this.page.pageSize) {
                this.diseaseList = diseasePestList.slice(0, this.page.pageSize);
            } else {
                this.diseaseList = diseasePestList;
            }
        },
        // 改变页码，重新渲染页面
        onChangePageList() {
            this.diseaseList = this.diseaseTotalList.slice( this.page.pageSize * (this.page.currentPage - 1), this.page.pageSize * this.page.currentPage);
        }
    }
};
</script>
<style lang="less" scoped>
    @import '../../../assets/css/selectCondition';
    .publiceCommon{
        border-bottom: 1px dashed #d9d9d9;

    }
    .publiceCommon:nth-child(2n){
        border-top:none;
    }
    .publiceCommon:last-child{
        border-bottom:none;
    }

    .leftContent{
        width:100%;
        .field{
            font-size:14px;
            color: #333;
            span{
                width: 50%;
                display: inline-block;
                margin-bottom:8px;
            }
            p{
                width:100%;
                margin-bottom:8px;
            }
        }
        .scientificName{
            span{
                height: 24px;
                background-color: #ff5454;
                border-radius: 4px;
                display: inline-block;
                text-align: center;
                line-height: 24px;
                color:#fff;
                font-size: 12px;
                padding: 0 5px;
                margin-left:14px;
            }
        }
        .germplasmMenuList{
            flex-wrap: wrap;
            div{
                width: 17.8%;
                height: 260px;
                border-radius: 4px;
                border: solid 1px #d9d9d9;
                text-align: center;
                font-size: 16px;
                color:#333;
                margin:0 2% 24px 0;
            }
            /deep/ .el-image{
                margin:0;
            }
            //div:nth-child(5n) {
            //    margin-right: 0;
            //}
            span{
                display: inline-block;
                width:80%;
                margin-top:7px;
            }
        }
    }
    .msg{
    text-align: center;
    font-size:18px;
    color:#333;
}
.downLoad{
    text-align: center;
    a{
        width: 130px;
        height: 36px;
        background-color: #265ae1;
        border-radius: 2px;
        text-align: center;
        line-height: 36px;
        color: #fff;
        font-size: 16px;
        margin: 20px 0;
        display: inline-block;
    }

}
</style>
