<template>
    <div>
        <div class='tab'>
            <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
            <div class='tabContent'>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="南繁基地数据" name="0">
                      <base-list></base-list>
                    </el-tab-pane>
                    <el-tab-pane label="病虫害数据" name="1">
                        <pest-list></pest-list>
                    </el-tab-pane>
                    <el-tab-pane label="专家人才数据" name="2">
                        <expert-talent></expert-talent>
                    </el-tab-pane>
                    <el-tab-pane label="实验室数据" name="3">
                        <lab-list></lab-list>
                    </el-tab-pane>
                    <el-tab-pane label="仪器数据" name="4">
                        <instrument-list></instrument-list>
                    </el-tab-pane>
                </el-tabs>
                <div class='myDataShare publishData' @click='publishData'>我要发布</div>
                <div class='myDataShare' @click='onDownLoadApp'>我的共享发布</div>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import baseList from './component/baseList.vue';
import pestList from './component/pestList.vue';
import expertTalent from './component/expertTalent.vue';
import labList from './component/labList.vue';
import instrumentList from './component/instrumentList.vue';

export default {
    name: 'dataShare',
    components: {
        nfBreadcrumb,
        baseList,
        pestList,
        expertTalent,
        labList,
        instrumentList
    },
    data() {
        return {
          startTime: null,
          pageName: "共享发布(dataShare.vue)",
             activeName: '0',
             breadList: [{
                name: '资源共享'
            }, {
                name: '共享发布'
            }]
        };
    },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
    mounted() {
    },
    methods: {
      trackNetworkRequest(url) {
        let requestStartTime = performance.now();
        let this_ = this;

        fetch(url)
            .then(response => {
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestSuccessTime", requestTime);
              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "success",
                  requestResult: response.toString()
                }
              })
            })
            .catch(error=>{
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestErrorTime", requestTime);

              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "error",
                  requestResult: error.toString()
                }
              })
            })
      },

      onDownLoadApp() {
            this.$router.push({
                path: '/downLoad'
            });
        },
        publishData() {
            this.$router.push({
                path: '/dataShare/publishData',
                query: {
                    type: this.activeName
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
    /deep/ .el-tabs__item{
        width: 130px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        padding:0;
    }
    /deep/ .el-tabs__nav{
        padding: 10px 0;
    }
    .tabContent{
        position: relative;
        width:95%;
        margin: 0 auto;
    }
     .myDataShare{
        width: 128px;
        height: 36px;
        background-color: #265ae1;
        border-radius: 2px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        padding:0;
        outline: none;
        border: none;
        margin-bottom:18px;
        position: absolute;
        right:0;
        top:8px;
        border:1px solid #265ae1;
        cursor: pointer;
    }
    .publishData{
        right:140px;
        background: #fff;
        border:1px solid #265ae1;
        color:#265ae1;
    }
</style>
