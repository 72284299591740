<template>
    <div  v-loading="loading">
        <div class='source'>说明：基地管理模块通过基地介绍、基地所属单位介绍、基地育种概况、基地成果一览、基地人员分布、人才队伍、产业发展、基地共享实验室、基地其他展示、基地地图信息、基地实况监控等多个维度进行基地展示。</div>
        <div v-if='data.length>0'>
            <div>
                <div class='list flex'>
                    <div v-for='(item, index) in data' class='listItem' :key='index' @click='onGoDetaile(item)'>
                        <!-- <img
                            :src="item.baseImg ? item.baseImg : item.urls[0]"
                            alt
                            v-if="item.baseImg || item.urls[0] "
                        />
                        <img
                            :src="item.urls[0] ? item.urls[0] :'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-baseprofile/base_picture/2019-09-23/d1e89482-c080-42fc-9350-090441389649.jpg'"
                            alt
                            v-if="!item.baseImg && !item.urls[0] "
                        /> -->
                         <el-image
                            style="width: 100%; height: 210px"
                            :src="item.baseImg ? item.baseImg : item.urls[0]"
                            v-if="item.baseImg || item.urls[0] "
                            fit="cover"></el-image>
                            <el-image
                            style="width: 100%; height: 210px"
                            :src="item.urls[0] ? item.urls[0] :'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-baseprofile/base_picture/2019-09-23/d1e89482-c080-42fc-9350-090441389649.jpg'"
                            v-if="!item.baseImg && !item.urls[0] "
                            fit="cover"></el-image>
                        <div class='name ellipsis'>{{item.baseName?item.baseName:"暂无数据"}}</div>
                        <div class='ellipsis'>{{item.baseBreedName?item.baseBreedName:"暂无数据"}}</div>
                        <div class='address flex'>
                            <img :src="require('@/assets/images/dataShare/address.png')" alt="">
                            <span class="ellipsis">{{item.baseAddress?item.baseAddress:"暂无数据"}}</span>
                        </div>
                    </div>
                </div>
                <pagination v-if='page.total > baseParam.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
            </div>
        </div>
        <div v-else-if='!loading' class='dataNull'>
            <no-data></no-data>
        </div>
        <el-dialog title="申请基地准入" :visible.sync="dialogFormVisible">
            <el-form :model='applyBase' :rules='rules' ref='applyBase'>
                <el-form-item label="申请手机号" :label-width="formLabelWidth" prop='phoneNum'>
                    <el-input v-model="applyBase.phoneNum"  autocomplete="off" disabled placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item label="申请人" :label-width="formLabelWidth" prop='applyName' required>
                   <el-input v-model="applyBase.applyName"  autocomplete="off"  placeholder="请输入申请人姓名"></el-input>
                </el-form-item>
                <el-form-item label="申请基地" :label-width="formLabelWidth" prop='baseNames'>
                    <el-input v-model="applyBase.baseNames"  autocomplete="off" disabled ></el-input>
                </el-form-item>
                <el-form-item label="申请理由" :label-width="formLabelWidth" prop='applyReason' required>
                   <el-input v-model="applyBase.applyReason" autocomplete="off" placeholder="请说明申请理由"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title=""
            :visible.sync="tip"
            width="30%">
            <div class='msg'>请下载APP可查看更多信息</div>
            <div class='downLoad'><router-link  :to='{path: "/downLoad"}' tag='a'>下载APP</router-link></div>
        </el-dialog>
    </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index';
import Vue from 'vue';
import { Loading, Message, MessageBox, Dialog, Image } from 'element-ui';
Vue.use(Dialog);
Vue.use(Image);
export default {
    components: {
        pagination,
        noData
    },
    data() {
        return {
            rules: {
                applyName: [
                    { required: true, message: '申请人不能为空' }
                ],
                applyReason: [
                    { required: true, message: '申请理由不能为空' }
                ]
            },
             data: [],
             page: {
                 total: 0,
                 currentPage: 1,
                 pageSize: 10
             },
             baseParam: {
                baseName: '',
                cityId: '',
                districtId: '',
                pageNum: 1,
                pageSize: 10,
                villageId: ''
             },
             dialogFormVisible: false,
             formLabelWidth: '100px',
             applyBase: {
                 applyName: '',
                 applyReason: '',
                 baseId: '',
                 baseNames: '',
                 phoneNum: ''
             },
             tip: false,
             loading: true
        };
    },
    mounted() {
        this.getBaseList();
    },
    methods: {
        // 获取基地列表
        async getBaseList() {
            this.loading = true;
            this.baseParam.pageNum = this.page.currentPage;
            const api = this.$fetchApi.baseScreeningContent.api;
            const data = await this.$fetchData.fetchPost(this.baseParam, api, 'json');
            this.loading = false;
            if (data.code === '200') {
                this.data = data.result.rows;
                this.page.total = data.result.total;
            }
        },
        // 分页
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            if ( this.page.currentPage < 3 ) {
                this.getBaseList(); //改变页码，重新渲染页面
            } else {
                this.tip = true;
            }

        },
        // 跳转详情
        async onGoDetaile(data) {
            // 1是已驳回 2可以访问 3去申请 4待审核中
            const api = this.$fetchApi.baseApplyVisitStatus.api;
            const res = await this.$fetchData.fetchPost({}, api + `/${data.id}`, 'json');
            if (res.code === '200') {
                if (res.result.flag === '1') {
                    MessageBox.confirm('该基地已驳回，不可以再申请', '提示', {
                        confirmButtonText: '确定',
                        showCancelButton: false,
                        type: 'info'
                    });
                } else if (res.result.flag === '2') {
                    this.$router.push({
                        path: '/dataShare/baseDetail',
                        query: {
                            id: data.id
                        }
                    });
                } else if (res.result.flag === '3') {
                    MessageBox.confirm('暂无该基地准入批准', '提示', {
                        confirmButtonText: '去申请',
                        showCancelButton: false,
                        type: 'info'
                    }).then(() => {
                       this.applyBase.applyName = res.result.realName;
                       this.applyBase.baseId = data.id;
                       this.applyBase.baseNames = data.baseName;
                       this.applyBase.phoneNum = res.result.phoneNum;
                       this.dialogFormVisible = true;
                    });
                } else if (res.result.flag === '4') {
                    MessageBox.confirm('该基地已申请，等待审核中', '提示', {
                        confirmButtonText: '确认',
                        showCancelButton: false,
                        type: 'info'
                    });
                }
            }
        },
        // 提交申请
        async addSubmit() {
             this.$refs.applyBase.validate((valid) => {
                if (valid) {
                    this.applyRecord();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });

        },
        async applyRecord() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const param = {
                applyName: this.applyBase.applyName,
                applyReason: this.applyBase.applyReason,
                baseId: this.applyBase.baseId
            };
            this.dialogFormVisible = false;
            const api = this.$fetchApi.addSubmit.api;
            const res = await this.$fetchData.fetchPost(param, api, 'json');
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
        }
    }

};
</script>
<style lang="less" scoped>
    @import '../common.less';
    .dataNull{
        margin:100px auto;
        width:1200px;
    }
    .source{
      width: 96%;
      background-color: #b4d1ff;
      border-radius: 4px;
      font-size: 18px;
      color: #448afc;
      padding: 20px;
      margin: 0 auto;
    }

    .list{
        .address img{
            width:13px;
            height:15px;
            margin-right:7px;
        }
        .address{
            span{
                width:89%;
            }
        }
        .listItem /deep/ .el-image{
            margin:0;
        }
    }
</style>
