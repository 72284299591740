<template>
    <div class='' v-loading="loading">
        <div v-if='data.length>0'>
            <div>
                <div class='list flex'>
                    <div v-for='(item, index) in data' class='listItem' :key='index' @click='onGoDetaile(item.id)'>
                        <el-image
                            style="width: 100%; height: 210px"
                            :src="item.imageUrl || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'"
                            fit="cover"></el-image>
                        <div class='name ellipsis'>{{item.name?item.name:"暂无数据"}}</div>
                        <div class='ellipsis'>地址：{{item.address?item.address:"暂无数据"}}</div>
                        <div class='ellipsis'>服务范围：{{item.serviceScope?item.serviceScope:"暂无数据"}}</div>
                    </div>
                </div>
                <pagination v-if='page.total > param.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
            </div>
        </div>
        <div v-else class='dataNull'>
            <no-data></no-data>
        </div>
        <el-dialog
            title=""
            :visible.sync="tip"
            width="30%">
            <div class='msg'>请下载APP可查看更多信息</div>
            <div class='downLoad'><router-link  :to='{path: "/downLoad"}' tag='a'>下载APP</router-link></div>
        </el-dialog>
    </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index';
import Vue from 'vue';
import { Dialog, Image } from 'element-ui';
Vue.use(Dialog);
Vue.use(Image);
export default {
    components: {
        pagination,
        noData
    },
    data() {
        return {
             data: [],
             page: {
                 total: 0,
                 currentPage: 1,
                 pageSize: 10
             },
            param: {
                cityType: '',
                keyword: '',
                pageNum: 1,
                pageSize: 10
             },
             tip: false,
             loading: true
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
             if (this.page.currentPage < 3) {
                this.initData(); // 改变页码，重新渲染页面
            } else {
                this.tip = true;
            }
        },
        async initData() {
            this.loading = true;
            this.param.pageNum = this.page.currentPage;
            const api = this.$fetchApi.laboratory.api;
            const data = await this.$fetchData.fetchPost(this.param, api, "json");
            this.loading = false;
            if (data.code === '200' && data.result) {
                this.data = data.result.list;
                if (this.page.currentPage === 1) {
                    this.page.total = data.result.total;
                }
            }
        },
        onGoDetaile(id) {
            this.$router.push({
                path: '/labShare/labDetaile',
                query: {
                    id
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
    @import '../common.less';
    .dataNull{
        margin:100px auto;
        width:1200px;
    }
</style>
