<template>
  <div class="expert" v-loading="loading">
    <!-- 列表 -->
    <div v-if='list.length > 0'>
        <div class='list flex'>
            <div v-for='(item, index) in list' class='listItem' :key='index' @click='onGoDetaile(item)'>
                <el-image
                  style="width: 100%; height: 210px"
                  :src="item.photo || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'"
                  fit="cover"></el-image>
                <div class='name ellipsis'>{{item.name?item.name:"暂无数据"}}</div>
                <div class='ellipsis'>专业：{{item.professionalism?item.professionalism:"暂无数据"}}</div>
                <div class='address flex'>
                    <span class="ellipsis">工作单位：{{item.workUnit?item.workUnit:"暂无数据"}}</span>
                </div>
            </div>
        </div>
        <pagination v-if='page.total > params.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
    </div>
    <div v-else class="noData">
      <no-data></no-data>
    </div>
    <el-dialog
        title=""
        :visible.sync="tip"
        width="30%">
        <div class='msg'>请下载APP可查看更多信息</div>
        <div class='downLoad'><router-link  :to='{path: "/downLoad"}' tag='a'>下载APP</router-link></div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index.vue';
import Vue from 'vue';
import { Dialog, Image } from 'element-ui';
Vue.use(Dialog);
Vue.use(Image);
export default {
    components: {
        pagination,
        noData
    },
  name: 'expert',
  data() {
    return {
      // 请求参数
        params: {
            pageNum: 1,
            pageSize: 10,
            expertType: ''
        },
        page: {
            total: 0,
            currentPage: 1,
            pageSize: 10
        },
        list: [], // 列表
        tip: false,
        loading: true
    };
  },
  methods: {
    // 获取列表
    async getList() {
      this.loading = true;
      this.params.pageNum = this.page.currentPage;
      const api = this.$fetchApi.expertTalentList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      this.loading = false;
      if (data.code === '200' && data.result) {
        this.page.total = data.result.total;
        this.list = data.result.list;
      }
    },
    // 页码变化
    async pageChange(page) {
        this.page.currentPage = page.page_index;
        this.page.pageSize = page.page_limit;
        if (this.page.currentPage < 3) {
          this.getList(); // 改变页码，重新渲染页面
        } else {
          this.tip = true;
        }
    },
    onGoDetaile(item) {
      this.$router.push({
        path: '/expertTalentDetail',
        query: { id: item.id }
      });
    }
  },
  created() {
    this.getList();
  }
};
</script>

<style lang="less" scoped>
 @import '../common.less';
    .dataNull{
        margin:100px auto;
        width:1200px;
    }
    .source{
      width: 1140px;
      background-color: #b4d1ff;
      border-radius: 4px;
      font-size: 18px;
      color: #448afc;
      padding: 20px;
      margin: 0 auto;
    }
    .list{
        .address img{
            width:13px;
            height:15px;
            margin-right:7px;
        }
        .address{
            span{
                width:89%;
            }
        }
    }
</style>
